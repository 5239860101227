;(function ($, window, document, undefined) {
    'use strict';

    if (typeof fitVids === 'function') {
        $('body').fitVids({ignore: '.vimeo-video, .youtube-simple-wrap iframe, .iframe-video.for-btn iframe, .post-media.video-container iframe'});
    }

    /*=================================*/
    /* PAGE CALCULATIONS */
    /*=================================*/

    if (typeof pageCalculations !== 'function') {

        var winW, winH, pageCalculations, onEvent = window.addEventListener;

        pageCalculations = function (func) {

            winW = window.innerWidth;
            winH = window.innerHeight;

            if (!func) return;

            onEvent('load', func, true); // window onload
            onEvent('resize', func, true); // window resize
            onEvent("orientationchange", func, false); // window orientationchange

        }; // end pageCalculations

        pageCalculations(function () {
            pageCalculations();
        });
    }

    $(window).on('load', function () {
        wpc_add_img_bg('.js-bg');
    });

    function calcPaddingMainWrapper() {

        if ($('.custo-footer').length) {
            var footer = $('.custo-footer');
            var paddValue = footer.outerHeight();

            footer.bind('heightChange', function () {
                $('body').css('padding-bottom', paddValue);

            });

            footer.trigger('heightChange');
        }
    }

    function blogIsotope() {
        if ($('.custo-blog--isotope').length) {
            $('.custo-blog--isotope').each(function () {
                var self = $(this);
                self.isotope({
                    itemSelector: '.custo-blog--post',
                    layoutMode: 'masonry',
                    masonry: {
                        columnWidth: '.custo-blog--post'
                    }
                });
            });
        }
    }

    function wpc_add_img_bg(img_sel, parent_sel) {
        if (!img_sel) {
            return false;
        }

        var $parent, $imgDataHidden, _this;
        $(img_sel).each(function () {
            _this = $(this);
            $imgDataHidden = _this.data('s-hidden');
            $parent = _this.closest(parent_sel);
            $parent = $parent.length ? $parent : _this.parent();
            $parent.css('background-image', 'url(' + this.src + ')').addClass('s-back-switch');
            if ($imgDataHidden) {
                _this.css('visibility', 'hidden');
                _this.show();
            }
            else {
                _this.hide();
            }
        });
    }

    function adminBarPositionFix() {
        if ($('#wpadminbar').length) {
            $('#wpadminbar').css('position', 'fixed');
        }
    }

    function errorPageHeight() {
        if ($('.custo-error--wrap').length) {
            var footerH = $('.custo-footer').length ? $('.custo-footer').outerHeight() : 0,
                headerH = $('.custo-header--wrap').length ? $('.custo-header--wrap').outerHeight() : 0,
                errorH = $(window).height() - footerH - headerH;

            $('.custo-error--wrap').outerHeight(errorH);
        }
    }


    $('.custo-blog--single__comments-button').on('click', function () {

        $(this).toggleClass('active');
        if ($('.custo-blog--single__comments').length) {
            $('.custo-blog--single__comments').slideToggle();
        }
    });


    $(window).on('load', function () {
        $('.custo-preloader').fadeOut(200);
    });
    $(window).on('load resize orientationchange', function () {
        calcPaddingMainWrapper();
        setTimeout(blogIsotope, 200);
        adminBarPositionFix();
        errorPageHeight();
    });

})(jQuery, window, document);